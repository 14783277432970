import React, { FC } from 'react'
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native'
import { Typography, TypographyProps } from '../Typography/Typography'

export interface WideCardProps {
    image?: React.ReactNode
    imageContainerStyle?: StyleProp<ViewStyle>
    title?: string
    titleStyle?: Omit<TypographyProps, 'children'>
    cardContainerStyle?: StyleProp<ViewStyle>
    leftContainerStyle?: StyleProp<ViewStyle>
    rightContainerStyle?: StyleProp<ViewStyle>
    rightContent?: React.ReactNode
    descriptionContent?: React.ReactNode
    testID?: string
}

export const WideCard: FC<WideCardProps & {}> = (props) => {
    const {
        image,
        imageContainerStyle,
        title,
        cardContainerStyle,
        leftContainerStyle,
        titleStyle,
        descriptionContent,
        rightContainerStyle,
        rightContent,
        testID = 'wide-card',
    } = props

    return (
        <View
            style={[styles.cardContainer, cardContainerStyle]}
            testID={testID}
        >
            {image ? (
                <View
                    style={[styles.imageView, imageContainerStyle]}
                    testID={`${testID}-image`}
                >
                    {image}
                </View>
            ) : null}
            <View
                style={[styles.leftContainer, leftContainerStyle]}
                testID={`${testID}-left-container`}
            >
                {title ? (
                    <Typography
                        variant="lg"
                        {...titleStyle}
                        testID={`${testID}-typography`}
                    >
                        {title}
                    </Typography>
                ) : null}
                {descriptionContent ? descriptionContent : null}
            </View>
            {rightContent ? (
                <View
                    style={rightContainerStyle}
                    testID={`${testID}-right-container`}
                >
                    {rightContent}
                </View>
            ) : null}
        </View>
    )
}

const styles = StyleSheet.create({
    cardContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    leftContainer: {
        flex: 1,
        justifyContent: 'space-between',
    },
    imageView: {
        backgroundColor: '#F1F5F9',
        borderRadius: 10,
    },
})
