import React, { FC } from 'react'
import { Pressable, Text, Image, View, StyleSheet } from 'react-native'
import COLORS from '../../../src/utils/colors'
import { CardBaseProps, CardTitleProps } from '../Card/interface'

export interface TaskCardProps extends CardBaseProps, CardTitleProps {
    detail?: React.ReactNode
    imageUrls?: string[]
    isOverdue?: boolean
    label?: string
    onPressText?: string
}

const TaskCardLoading: React.FC = () => {
    return (
        <View style={styles.taskCardLoadingContainer}>
            <View style={styles.rowSpaceBetween}>
                <View style={styles.taskCardTitleLoading} />

                <View style={styles.taskCardImageLoading} />
            </View>

            <View style={styles.rowSpaceBetween}>
                <View style={styles.taskCardDetailLoading} />

                <View style={styles.taskCardOverdueLoading} />
            </View>
        </View>
    )
}

export const TaskCard: FC<TaskCardProps> = ({
    pressableProps,
    testID,
    containerStyle,
    title,
    detail,
    imageUrls,
    isOverdue,
    loading,
}) => {
    if (loading) {
        return <TaskCardLoading />
    }

    return (
        <Pressable {...pressableProps} testID={`${testID}-task-card`}>
            <View style={[styles.taskCardContainer, containerStyle]}>
                <View style={styles.spaceBetween}>
                    <Text style={[styles.taskCardTitleStyle]}>{title}</Text>

                    <View style={[styles.taskCardContentStyle]}>{detail}</View>
                </View>

                <View style={styles.spaceBetween}>
                    <View testID={`${testID}-task-image`}>
                        {imageUrls &&
                            imageUrls.map((imageUrl, index) => (
                                <Image
                                    key={imageUrl}
                                    style={[
                                        {
                                            ...styles.imageStyle,
                                            right: index * 16,
                                            zIndex: imageUrls.length - index,
                                        },
                                    ]}
                                    source={{
                                        uri: imageUrl,
                                    }}
                                />
                            ))}
                    </View>

                    {isOverdue && (
                        <View style={styles.overdueContainerStyle}>
                            <Text style={styles.overdueTextStyle}>OVERDUE</Text>
                        </View>
                    )}
                </View>
            </View>
        </Pressable>
    )
}

const styles = StyleSheet.create({
    taskCardContainer: {
        minWidth: 327,
        minHeight: 104,
        backgroundColor: 'white',
        borderRadius: 6,
        borderLeftColor: COLORS.COLOR_PRIMARY_PURPLE,
        borderLeftWidth: 8,
        paddingHorizontal: 20,
        paddingVertical: 16,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    taskCardTitleStyle: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    taskCardContentStyle: {
        paddingTop: 5,
    },
    imageStyle: {
        height: 32,
        width: 32,
        resizeMode: 'cover',
        position: 'absolute',
        borderRadius: 100,
        borderWidth: 1,
        borderColor: '#F0E5FF',
    },
    overdueContainerStyle: {
        marginTop: 14,
        backgroundColor: '#FEF3C7',
        paddingVertical: 2,
        paddingHorizontal: 6,
        borderRadius: 38,
    },
    overdueTextStyle: {
        fontSize: 12,
        color: '#D97706',
        fontWeight: 'bold',
    },
    taskCardLoadingContainer: {
        width: 327,
        height: 104,
        backgroundColor: 'white',
        borderRadius: 6,
        borderLeftWidth: 8,
        paddingHorizontal: 20,
        paddingVertical: 16,
        borderLeftColor: COLORS.COLOR_PRIMARY_PURPLE,
    },
    taskCardTitleLoading: {
        width: '30%',
        height: 20,
        backgroundColor: COLORS.COLOR_BTNGRAY,
        borderRadius: 8,
        marginTop: 10,
    },
    taskCardImageLoading: {
        width: 20,
        height: 20,
        backgroundColor: COLORS.COLOR_BTNGRAY,
        borderRadius: 8,
        marginTop: 10,
    },
    taskCardDetailLoading: {
        width: '50%',
        height: 20,
        backgroundColor: COLORS.COLOR_BTNGRAY,
        borderRadius: 8,
        marginTop: 10,
    },
    taskCardOverdueLoading: {
        width: '20%',
        height: 20,
        backgroundColor: COLORS.COLOR_WARNING,
        borderRadius: 8,
        marginTop: 10,
    },
    spaceBetween: {
        justifyContent: 'space-between',
    },
    rowSpaceBetween: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
})
