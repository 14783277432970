import React, { useState } from 'react'
import { View, Text, ViewStyle, StyleSheet } from 'react-native'
import { RadioButton, RadioButtonProps } from './RadioButton'
import COLORS from '../../utils/colors'

export type RadioButtonGroupProps = {
    title: string
    titleStyle?: ViewStyle
    containerStyle?: ViewStyle
    radioButtons: RadioButtonProps[]
    onSelectionChange?: (selectedIndex: number) => void
    testID?: string
}

export const RadioButtonGroup: React.FC<RadioButtonGroupProps> = (
    props: RadioButtonGroupProps,
) => {
    const {
        title,
        titleStyle,
        radioButtons,
        containerStyle,
        onSelectionChange,
        testID,
    } = props

    const [selectedIndex, setSelectedIndex] = useState<number>(() => {
        const selectedIndexInProps = radioButtons.findIndex(
            (radioButton) => radioButton.checked === true,
        )

        return selectedIndexInProps > -1 ? selectedIndexInProps : 0
    })

    return (
        <View style={containerStyle} testID={testID}>
            <Text style={[styles.titleStyle, titleStyle]}>{title}</Text>
            {radioButtons.map((radioButtonProps, index: number) => {
                return (
                    <RadioButton
                        {...radioButtonProps}
                        checked={selectedIndex === index}
                        onPress={() => {
                            setSelectedIndex(index)
                            if (onSelectionChange) {
                                onSelectionChange(index)
                            }
                        }}
                        key={`${radioButtonProps.title || ''}${index}`}
                    />
                )
            })}
        </View>
    )
}

const styles = StyleSheet.create({
    titleStyle: {
        paddingLeft: 20,
        fontFamily: 'Arial',
        fontSize: 14,
        fontWeight: 'bold',
        color: COLORS.COLOR_GRAY,
    },
})
